import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { hideVisually } from "polished"
import { Modal } from "@outdoormap/gatsby-plugin-brand-sites"

import SearchForm from "../search-form"
import useRefCallback from "../../utils/use-ref-callback"

export default function SearchModal({ isOpen, onRequestClose }) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        pages {
          search {
            title
          }
        }
      }
    }
  `)

  const inputRef = useRefCallback((node) => {
    window.setTimeout(() => node && node.focus(), 100)
  })

  const formSubmit = () => {
    onRequestClose()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Container>
        <Title>{translations.pages.search.title}</Title>

        <Inner>
          <SearchForm
            formProps={{ onSubmit: formSubmit }}
            inputProps={{ ref: inputRef, inModal: true }}
          />
        </Inner>
      </Container>
    </Modal>
  )
}

const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Inner = styled.div`
  ${({ theme }) => theme.grid.container(`xnarrow`)}
`

const Title = styled.h2`
  ${hideVisually()}
`
