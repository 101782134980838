import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { useCookies } from "react-cookie"

import About from "./about"
import Menus from "./menus"
import GoodToKnow from "./good-to-know"
import Link from "../link"
import { Anchor } from "../styled/anchor"
import { ParagraphSmall } from "../styled/paragraph"
import euLeaderLogoUrl from "../../assets/images/eu-leader-logo.svg"
import euFundedLogoUrl from "../../assets/images/eu-funded-logo.svg"
import naturkartanLogoUrl from "../../assets/images/naturkartan-logo-white.svg"

const LOGOS = [
  {
    title: `LEADER`,
    image: euLeaderLogoUrl,
  },
  {
    title: `Europeiska jordbruksfonden för landsbygdsutveckling. Europa investerar i landsbygdsomräden`,
    image: euFundedLogoUrl,
  },
]

export default function Footer() {
  const [cookies, , removeCookie] = useCookies([`cookies-consent`])

  const cookiesConsented = !!cookies[`cookies-consent`]

  const resetCookiesClick = () => {
    removeCookie(`cookies-consent`)
    window.scrollTo({ top: 0, behavior: `instant` })
    window.location.reload()
  }

  const { site, translations } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          name
          lang
        }
      }

      translations: translationsJson {
        footer {
          resetCookies: reset_cookies
        }
      }
    }
  `)

  return (
    <Container>
      <GoodToKnow />

      <Main>
        <div>
          <Sections>
            <div>
              <AboutWrap>
                <About />
              </AboutWrap>
            </div>

            <div>
              <div>
                <Menus />

                <Copyright>
                  © {site.siteMetadata.name} & OutdoorMap.
                  {` `}
                  {cookiesConsented && (
                    <Anchor
                      as="button"
                      type="button"
                      onClick={resetCookiesClick}
                    >
                      {translations.footer.resetCookies}
                    </Anchor>
                  )}
                </Copyright>

                {!!LOGOS.length && (
                  <Logos>
                    {LOGOS.map((logo, i) => (
                      <li key={i}>
                        <img
                          src={logo.image}
                          alt={logo.title}
                          title={logo.title}
                          loading="lazy"
                        />
                      </li>
                    ))}
                  </Logos>
                )}
              </div>
            </div>
          </Sections>

          <Naturkartan>
            <Link
              to={`https://www.naturkartan.se/${site.siteMetadata.lang}`}
              target="_blank"
              title="Naturkartan"
            >
              <img src={naturkartanLogoUrl} alt="Naturkartan" />
            </Link>
          </Naturkartan>
        </div>
      </Main>
    </Container>
  )
}

const Container = styled.footer``

const Main = styled.div`
  padding-top: ${em(70)};
  padding-bottom: ${em(70)};
  color: ${({ theme }) => theme.colors.grasten};
  background-color: ${({ theme }) => theme.colors.abla};

  @media ${({ theme }) => theme.mq.xxlargeDown} {
    padding-top: ${em(40)};
  }

  > div {
    ${({ theme }) => theme.grid.container()}
  }
`

const Sections = styled.div`
  padding: ${em(56)} 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: ${em(30)};
  background-image: ${({ theme }) =>
      theme.helpers.waveUrl(theme.colors.sandbeige, true)},
    ${({ theme }) => theme.helpers.waveUrl(theme.colors.sandbeige)};
  background-position: center top, center bottom;
  background-repeat: repeat-x;
  background-size: ${em(30)}, ${em(36)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    grid-template-columns: 1fr;
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    background-size: ${em(24)} ${em(30)};
  }
`

const AboutWrap = styled.div`
  width: 100%;
  max-width: ${em(560)};

  @media ${({ theme }) => theme.mq.mediumUp} {
    padding-right: clamp(${em(20)}, 20%, ${em(80)});
  }
`

const Logos = styled.ul`
  margin-top: ${em(30)};
  display: flex;
  flex-wrap: wrap;
  gap: ${em(16)};

  li {
    width: ${em(120)};
    padding: ${em(6)};
    flex-shrink: 0;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.br.small};
  }

  img {
    width: 100%;
    display: block;
  }
`

const Copyright = styled(ParagraphSmall)`
  margin-top: ${em(22)};
  opacity: 0.8;
  font-size: ${em(14)};

  button {
    color: inherit;
  }
`

const Naturkartan = styled.div`
  margin-top: ${em(60)};
  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(30)};
  }

  a {
    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.5;
    }
  }

  img {
    width: 100%;
    max-width: ${em(140)};
  }
`
