import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import SearchForm from "../components/search-form"
import SearchResult from "../components/search-result"
import { Heading1 } from "../components/styled/heading"
import { Paragraph, ParagraphSmall } from "../components/styled/paragraph"

const fetchApi = async (query, start = 0) => {
  try {
    const formattedQuery = query.trim().toLowerCase()
    const response = await window.fetch(
      `https://www.googleapis.com/customsearch/v1/siterestrict?key=${process.env.GATSBY_SEARCH_API_KEY}&cx=${process.env.GATSBY_SEARCH_ENGINE_ID}&num=10&q=${formattedQuery}&start=${start}`
    )
    const results = await response.json()
    return results
  } catch {
    return null
  }
}

export default function SearchTemplate({
  query = ``,
  data: { site, translations },
}) {
  const [results, setResults] = useState([])
  const [resultsCount, setResultsCount] = useState(0)
  const [nextPageIndex, setNextPageIndex] = useState(0)
  const [searching, setSearching] = useState(false)
  const [inputValue, setInputValue] = useState(query)

  const doSearch = (index) => {
    if (query) {
      setSearching(true)
      ;(async () => {
        const api = await fetchApi(query, index)
        setSearching(false)

        if (api && api.searchInformation.totalResults > 0) {
          setResults(index > 1 ? [...results, ...api.items] : api.items)
          setResultsCount(api.searchInformation.totalResults)
          setNextPageIndex(api.queries?.nextPage?.[0]?.startIndex || 0)
        } else {
          setResults([])
          setResultsCount(0)
          setNextPageIndex(0)
        }
      })()
    } else {
      setSearching(false)
      setResults([])
      setResultsCount(0)
      setNextPageIndex(0)
    }
  }

  useEffect(() => {
    doSearch()
  }, [query])

  const moreButtonClick = () => {
    doSearch(nextPageIndex)
  }

  return (
    <Layout>
      <Container>
        <Heading1>{translations.pages.search.title}</Heading1>

        <SearchFormWrap>
          <SearchForm
            inputProps={{
              value: inputValue,
              placeholder: `${translations.pages.search.title} ${site.siteMetadata.name}`,
              onChange: (e) => setInputValue(e.target.value),
            }}
          />
        </SearchFormWrap>

        {query && (
          <Info>
            {searching ? (
              <>{translations.pages.search.loadingResults}…</>
            ) : (
              <>
                <strong>{resultsCount}</strong>
                {` `}
                {translations.pages.search.results?.toLowerCase()}
              </>
            )}
          </Info>
        )}

        {!searching && query && !results.length && (
          <Paragraph>{translations.pages.search.noResults}</Paragraph>
        )}

        {!searching && !!results.length && (
          <Results>
            {results.map((result) => (
              <SearchResult key={result.cacheId} result={result} />
            ))}
          </Results>
        )}

        {searching && (
          <Loader>
            {Array.from({ length: 3 }).map((_, i) => (
              <div key={i} />
            ))}
          </Loader>
        )}

        {!!nextPageIndex && (
          <More>
            <button type="button" onClick={moreButtonClick}>
              {translations.pages.search.loadMoreResults}
            </button>
          </More>
        )}
      </Container>
    </Layout>
  )
}

export const Head = ({ query, data: { translations } }) => {
  return (
    <Meta
      data={{
        title: query
          ? `${translations.pages.search.title} “${query}”`
          : `${translations.pages.search.title}`,
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
      }
    }

    translations: translationsJson {
      pages {
        search {
          title
          results
          noResults: no_results
          loadingResults: loading_results
          loadMoreResults: load_more_results
        }
      }
    }
  }
`

const Container = styled.div`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  min-height: 50vh;
  padding-top: ${em(40)};
  padding-bottom: ${em(80)};
`

const SearchFormWrap = styled.div`
  margin-top: ${em(24)};
`

const Info = styled(ParagraphSmall)`
  margin: ${em(20)} 0 ${em(40)} ${em(26)};
  text-align: right;
  opacity: 0.8;
`

const Loader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${em(30)};

  div {
    height: ${em(180)};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.br.normal};
    animation: ${({ theme }) => theme.animations.fadeBlink} 1s
      ${({ theme }) => theme.easings.default} infinite;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }
`

const Results = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${em(30)};
`

const More = styled.div`
  padding-top: ${em(20)};
  display: flex;
  justify-content: center;

  button {
    ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

    padding: ${em(10)} ${em(30)};
    background-color: ${({ theme }) => theme.colors.skogsgront};
    color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.br.normal};
    font-size: ${em(16)};
    text-transform: uppercase;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.5;
    }
  }
`
