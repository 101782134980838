import React, { useMemo } from "react"
import styled, { css } from "styled-components"
import { em } from "polished"
import { chunk } from "lodash"

import Button from "../button"
import PostCardHighlight from "../post-card-highlight"
import { Heading2 } from "../styled/heading"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

export default function PostsHighlights({
  posts,
  postHeadingNum,
  heading,
  headingNum,
  moreText,
  moreLink,
  contained = true,
}) {
  const postsChunks = useMemo(
    () => (posts?.length ? chunk(posts, 3) : []),
    [posts]
  )

  if (!postsChunks.length) return null

  return (
    <Container as={heading ? `section` : `div`} contained={contained}>
      <div>
        {heading && (
          <Heading as={`h${headingNum}`}>
            <Heading2 as="span" dangerouslySetInnerHTML={{ __html: heading }} />
          </Heading>
        )}

        {postsChunks.map((chunk, i) => (
          <Highlights key={i} reversed={(i + 1) % 2 == 0}>
            {chunk.map((post, ii) => (
              <li key={post.id}>
                <PostCardHighlight
                  post={post}
                  headingNum={postHeadingNum || headingNum + 1}
                  major={ii == 0}
                />
              </li>
            ))}
          </Highlights>
        ))}

        {moreText && moreLink && (
          <More>
            <Button
              to={moreLink}
              icon={<SvgArrowRight />}
              iconpos="right"
              display="anchor"
            >
              {moreText}
            </Button>
          </More>
        )}
      </div>
    </Container>
  )
}

const Container = styled.section`
  ${({ theme, contained }) => contained && theme.grid.container()}
`

const Heading = styled.h2`
  margin-bottom: ${em(30)};
  color: ${({ theme }) => theme.colors.active};

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-bottom: ${em(20)};
  }
`

const Highlights = styled.ul`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: ${em(26)};

  ${({ reversed }) =>
    reversed &&
    css`
      transform: scale(-1, 1);

      > li {
        transform: scale(-1, 1);
      }
    `}

  @media ${({ theme }) => theme.mq.mediumDown} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${({ theme }) => theme.mq.xsmallDown} {
    grid-template-columns: 1fr;
  }

  & + & {
    margin-top: ${em(15)};
  }

  > li {
    &:first-child {
      grid-row: 1 / 3;

      @media ${({ theme }) => theme.mq.mediumDown} and ${({ theme }) =>
          theme.mq.xsmallUp} {
        grid-column: 1 / 3;
      }

      > * {
        @media ${({ theme }) => theme.mq.mediumUp} {
          min-height: 100%;
        }
      }
    }
  }
`

const More = styled.div`
  margin-top: ${em(30)};
  text-transform: uppercase;

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-top: ${em(18)};
  }
`
