/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"

import Root from "./src/components/root"

// wrapPageElement

export function wrapPageElement({ element }) {
  return <Root>{element}</Root>
}

export function onRouteUpdate({ location, prevLocation }) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: `gatsby-page-view` })

  // checks if route has changed
  if (location && prevLocation) {
    // triggers `gatsbyRouteChange` event for `window`
    const event = document.createEvent(`Event`)
    event.initEvent(`gatsbyRouteChange`, true, true)
    window.dispatchEvent(event)
  }
}
