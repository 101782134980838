import React from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import { em, hideVisually } from "polished"
import { Meta, Map } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import EventHero from "../components/event-hero"
import Infobox from "../components/infobox"
import SitesCarousel from "../components/sites-carousel"
import Styled from "../components/styled"
import { Heading6 } from "../components/styled/heading"

export default function EventPostTemplate({ data: { translations, event } }) {
  const SitesComponent = ({ mode }) => {
    if (!event.sites.length) return null

    return (
      <Sites mode={mode}>
        <SitesTitle as="h2">
          <Heading6
            as="span"
            dangerouslySetInnerHTML={{
              __html: translations.global.relatedSites,
            }}
          />
        </SitesTitle>

        <SitesCarousel
          sites={event.sites}
          siteHeadingNum={3}
          visibleSlides={2}
          contained={false}
        />
      </Sites>
    )
  }

  return (
    <Layout>
      <Container>
        <EventHero data={event} />

        <Columns>
          <Main>
            {event.body && (
              <Description>
                <Styled
                  leadingParagraph={true}
                  dangerouslySetInnerHTML={{ __html: event.body }}
                />
              </Description>
            )}

            <SitesComponent mode="desktop" />
          </Main>

          <Aside>
            {!!event.sites.length && (
              <MapWrap>
                <h2
                  dangerouslySetInnerHTML={{ __html: translations.global.map }}
                />

                <Map
                  query={event.sites.map((s) => `site=${s.id}`).join(`&`)}
                  menu="fullscreen"
                  scrollZoom="false"
                  styleControl="fullscreen"
                  zoomControl="fullscreen"
                  measureControl="fullscreen"
                  locationControl="fullscreen"
                  printControl="fullscreen"
                />
              </MapWrap>
            )}

            <Infobox data={event} />
          </Aside>
        </Columns>

        <SitesComponent mode="mobile" />
      </Container>
    </Layout>
  )
}

export const Head = ({ data: { event } }) => {
  return (
    <Meta
      data={{
        title: event.title,
        socialImage: event.images[0]?.images.fallback.src,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    translations: translationsJson {
      global {
        map
        relatedSites: related_sites
      }
    }

    event: eventsJson(id: { eq: $id }) {
      title
      body
      openTimes: open_times {
        opensIso: opens
        opens(formatString: "YYYY–MM–DD • HH:mm")
        closes(formatString: "HH:mm")
      }
      images {
        ...NKimageFragment
      }
      sites {
        ...SitesCardFragment
      }
      links {
        id
        url
        text
      }
      files {
        id
        url
        text
      }
    }
  }
`

const Container = styled.article`
  padding-bottom: ${em(120)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-bottom: ${em(40)};
  }
`

const Columns = styled.div`
  ${({ theme }) => theme.grid.container(`narrow`)}

  margin-top: ${em(50)};
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(${em(240)}, 1fr);
  column-gap: ${em(80)};
  row-gap: ${em(30)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(30)};
    grid-template-columns: 1fr;
  }
`

const Main = styled.div``

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  gap: ${em(30)};
`

const Description = styled.div`
  max-width: 60ch;
`

const Sites = styled.section`
  margin-top: ${em(40)};
  padding-top: ${em(40)};
  border-top: 1px solid ${({ theme }) => theme.colors.lightBorder};

  @media ${({ theme }) => theme.mq.mediumDown} {
    ${({ theme }) => theme.grid.container(`narrow`)}

    margin-top: ${em(20)};
    padding-top: ${em(20)};

    ${({ mode }) =>
      mode == `desktop` &&
      css`
        display: none;
      `}
  }

  @media ${({ theme }) => theme.mq.mediumUp} {
    ${({ mode }) =>
      mode == `mobile` &&
      css`
        display: none;
      `}
  }
`

const SitesTitle = styled.h2`
  margin-bottom: ${em(20)};
`

const MapWrap = styled.section`
  max-height: ${em(240)};
  aspect-ratio: 4 / 3;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.br.normal};

  > h2 {
    ${hideVisually()}
  }
`
