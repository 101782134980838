import React from "react"
import styled from "styled-components"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Styled from "../components/styled"
import Link from "../components/link"

export default function NotFoundPage() {
  return (
    <Layout>
      <Container>
        <Styled>
          <h1>Page not found</h1>

          <p>
            Go to the <Link to="/">homepage &rarr;</Link>
          </p>
        </Styled>
      </Container>
    </Layout>
  )
}

export const Head = () => {
  return <Meta data={{ title: `Page not found` }} />
}

const Container = styled.ul`
  ${({ theme }) => theme.grid.container()}

  min-height: 50vh;
  display: flex;
  align-items: center;
  text-align: center;

  > * {
    width: 100%;
  }
`
