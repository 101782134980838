import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { em } from "polished"

import Link from "../link"
import ImagePlaceholder from "../image-placeholder"
import { Heading4 } from "../styled/heading"

export default function PostCard({
  post: { title, path, image, date },
  headingNum,
  hideOutline,
}) {
  return (
    <Container hideOutline={hideOutline}>
      <Link to={path}>
        <Media>
          {image?.mediaType == `image` && (
            <GatsbyImage image={image} alt={title} />
          )}

          {image?.mediaType == `video` && (
            <video src={image.url} autoPlay playsInline loop muted />
          )}

          {!image && <ImagePlaceholder alt={title} />}
        </Media>

        <Info>
          {date && (
            <Date dateTime={date} dangerouslySetInnerHTML={{ __html: date }} />
          )}

          {title && (
            <Title as={`h${Math.min(headingNum, 6)}`}>
              <Heading4 as="span" dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          )}
        </Info>
      </Link>
    </Container>
  )
}

const Media = styled.figure`
  width: 100%;
  max-height: ${em(280)};
  aspect-ratio: 3 / 2;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.kullgront};
  border-top-left-radius: ${({ theme }) => theme.br.normal};
  border-top-right-radius: ${({ theme }) => theme.br.normal};

  @media ${({ theme }) => theme.mq.mediumDown} {
    max-height: ${em(180)};
  }

  .gatsby-image-wrapper,
  video {
    width: 100%;
    height: 100%;
    display: block;

    a:hover & {
      opacity: 0.9;
    }
  }

  video {
    object-fit: cover;
  }
`

const Info = styled.div`
  padding: ${em(16)} ${em(30)} ${em(22)};
`

const Date = styled.time`
  margin-bottom: ${em(4)};
  display: block;
  font-size: ${em(14)};
`

const Title = styled.h3``

const Container = styled.article`
  width: 100%;
  min-height: 100%;
  display: flex;

  a {
    width: 100%;
    min-height: 100%;
    outline-offset: ${em(6)};
    border-radius: ${({ theme }) => theme.br.normal};
    background-color: ${({ theme }) => theme.colors.white};

    ${({ hideOutline }) =>
      hideOutline &&
      css`
        outline: none;
      `}

    &:hover {
      background-color: ${({ theme }) => theme.colors.vattenbla200};
    }
  }
`
