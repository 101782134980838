import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { GatsbyImage } from "gatsby-plugin-image"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import PostsHighlights from "../components/posts-highlights"
import { Heading1 } from "../components/styled/heading"
import { Paragraph } from "../components/styled/paragraph"

export default function ArticlesTemplate({
  data: { translations, articles, image },
}) {
  const anyArticles = !!articles.nodes.length

  return (
    <Layout>
      {image && (
        <Image>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={translations.pages.articles.title}
          />
        </Image>
      )}

      <Content>
        <Title>
          <Heading1
            as="span"
            dangerouslySetInnerHTML={{
              __html: translations.pages.articles.title,
            }}
          />
        </Title>

        {anyArticles && (
          <PostsHighlights
            posts={articles.nodes}
            postHeadingNum={2}
            contained={false}
          />
        )}

        {!anyArticles && (
          <Empty>
            <Paragraph
              dangerouslySetInnerHTML={{
                __html: translations.global.noContent,
              }}
            />
          </Empty>
        )}
      </Content>
    </Layout>
  )
}

export const Head = ({ data: { translations, image } }) => {
  return (
    <Meta
      data={{
        title: translations.pages.articles.title,
        socialImage: image?.childImageSharp.gatsbyImageData.images.fallback.src,
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    translations: translationsJson {
      global {
        noContent: no_content
      }

      pages {
        articles {
          title
        }
      }
    }

    articles: allArticlesJson(sort: { publish_at: DESC }) {
      nodes {
        ...ArticlesCardFragment
      }
    }

    image: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/food.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

const Image = styled.div`
  height: 33vh;
  min-height: ${em(240)};
  max-height: ${em(480)};

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
    min-height: ${em(180)};
  }
`

const Content = styled.div`
  ${({ theme }) => theme.grid.container()}

  padding-top: ${em(50)};
  padding-bottom: ${em(80)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${em(30)};
    padding-bottom: ${em(40)};
  }
`

const Title = styled.h1`
  margin-bottom: ${em(30)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${em(20)};
  }
`

const Empty = styled.div`
  max-width: 60ch;
`
