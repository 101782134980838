import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { useCookies } from "react-cookie"

import { ParagraphSmall } from "../styled/paragraph"
import { gtmCode } from "../../utils/gtm"

export default function CookiesConsent() {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        cookiesConsent: cookies_consent {
          text
          accept
          deny
        }
      }
    }
  `)

  const gtmId = process.env.GATSBY_GTM_ID
  const cookieName = `cookies-consent`
  const [cookies, setCookie] = useCookies([cookieName])

  const buttonClick = (value) => {
    setCookie(cookieName, value, { path: `/`, maxAge: 3600 * 24 * 30 * 12 })

    if (value == `yes`) {
      const script = document.createElement(`script`)
      script.text = gtmCode(gtmId)
      document.body.appendChild(script)
    }
  }

  if (typeof window === `undefined` || !gtmId || cookies[cookieName])
    return null

  return (
    <Container>
      <Box>
        <ParagraphSmall
          dangerouslySetInnerHTML={{ __html: translations.cookiesConsent.text }}
        />

        <Buttons>
          <button
            type="button"
            onClick={() => buttonClick(`yes`)}
            dangerouslySetInnerHTML={{
              __html: translations.cookiesConsent.accept,
            }}
          />

          <button
            type="button"
            onClick={() => buttonClick(`no`)}
            dangerouslySetInnerHTML={{
              __html: translations.cookiesConsent.deny,
            }}
          />
        </Buttons>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  position: fixed;
  z-index: ${({ theme }) => theme.zindex.cookiesConsent};
  bottom: 0;
  right: 0;
  padding: ${em(30)};
  display: flex;
  justify-content: flex-end;
  pointer-events: none;

  @media ${({ theme }) => theme.mq.xsmallDown} {
    padding: ${em(10)};
  }
`

const Box = styled.div`
  width: 100%;
  max-width: ${em(320)};
  padding: ${em(24)};
  pointer-events: auto;
  color: ${({ theme }) => theme.colors.grasten};
  background-color: ${({ theme }) => theme.colors.granitsvart};
  border-radius: ${({ theme }) => theme.br.normal};
`

const Buttons = styled.div`
  margin-top: ${em(20)};
  display: flex;
  gap: ${em(12)};

  > button {
    padding: ${em(8)} ${em(16)};
    border: 1px solid;
    border-radius: ${({ theme }) => theme.br.small};

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.5;
    }

    &:first-child {
      ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

      background-color: ${({ theme }) => theme.colors.sandbeige};
      color: ${({ theme }) => theme.colors.granitsvart};
    }
  }
`
