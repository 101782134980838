import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { hideVisually, em } from "polished"
import { Meta, Map } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Hero from "../components/hero"
import SitesCarousel from "../components/sites-carousel"
import PostsHighlights from "../components/posts-highlights"
import CardsGrid from "../components/cards-grid"
import EventCard from "../components/event-card"
import PostCard from "../components/post-card"
import PostCardHighlight from "../components/post-card-highlight"
// import { typesCategoriesIds } from "../config/type"

export default function IndexPage({
  data: {
    translations: { types, ...translations },
    imgHero,
    latestArticles,
    latestEvents,
    latestNews,
    latestTours,
    hiking,
    paddling,
    biking,
    fishing,
    food,
    discover,
  },
}) {
  const sites = []

  const findType = (name) => types.find((t) => t.name === name)

  if (hiking.nodes.length)
    sites.push({ type: findType(`hiking`), items: hiking.nodes })

  if (paddling.nodes.length)
    sites.push({ type: findType(`paddling`), items: paddling.nodes })

  if (biking.nodes.length)
    sites.push({ type: findType(`biking`), items: biking.nodes })

  if (fishing.nodes.length)
    sites.push({ type: findType(`fishing`), items: fishing.nodes })

  if (food.nodes.length)
    sites.push({ type: findType(`food`), items: food.nodes })

  if (discover.nodes.length)
    sites.push({ type: findType(`discover`), items: discover.nodes })

  const mapQuery = [
    `guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`,
    // ...typesCategoriesIds.map((c) => `category=${c}`),
  ].join(`&`)

  return (
    <Layout>
      <Hero title={translations.pages.index.hero.title} image={imgHero} />

      <MapWrap>
        <h2 dangerouslySetInnerHTML={{ __html: translations.global.map }} />

        <Map
          query={mapQuery}
          menu="fullscreen"
          scrollZoom="false"
          styleControl="fullscreen"
          zoomControl="true"
          measureControl="fullscreen"
          locationControl="fullscreen"
          printControl="fullscreen"
          legendControl="true"
          legend="true"
          zoom={9}
        />
      </MapWrap>

      <Highlights>
        <CardsGrid
          heading={translations.pages.tours.title}
          headingNum={2}
          moreText={translations.global.seeAll}
          moreLink={`/${translations.pages.tours.slug}`}
          cols={2}
        >
          {latestTours.nodes.map((tour) => (
            <PostCardHighlight key={tour.id} post={tour} headingNum={3} />
          ))}
        </CardsGrid>

        <PostsHighlights
          posts={latestArticles.nodes}
          heading={translations.pages.articles.title}
          headingNum={2}
          moreText={translations.global.seeAll}
          moreLink={`/${translations.pages.articles.slug}`}
        />

        <CardsGrid
          heading={translations.pages.news.title}
          headingNum={2}
          moreText={translations.global.seeAll}
          moreLink={`/${translations.pages.news.slug}`}
          cols={3}
        >
          {latestNews.nodes.map((news) => (
            <PostCard key={news.id} post={news} headingNum={3} />
          ))}
        </CardsGrid>

        <CardsGrid
          heading={translations.pages.events.title}
          headingNum={2}
          moreText={translations.global.seeAll}
          moreLink={`/${translations.pages.events.slug}`}
        >
          {latestEvents.nodes.map((event) => (
            <EventCard key={event.id} event={event} headingNum={3} />
          ))}
        </CardsGrid>

        {sites.map(({ items, type }) => (
          <SitesCarousel
            key={type.name}
            sites={items}
            type={type}
            headingNum={2}
          />
        ))}
      </Highlights>
    </Layout>
  )
}

export const Head = ({ data: { translations } }) => {
  return (
    <Meta
      data={{
        title: translations.pages.index.title,
        titleOverridePattern: true,
      }}
    />
  )
}

export const pageQuery = graphql`
  query {
    translations: translationsJson {
      global {
        map
        seeAll: see_all
      }

      pages {
        index {
          title
          hero {
            title
          }
        }
        articles {
          title
          slug
        }
        news {
          title
          slug
        }
        events {
          title
          slug
        }
        tours {
          title
          slug
        }
      }

      types {
        name
        slug
        title
        tagline
      }
    }

    hiking: allSitesJson(
      filter: { the_type: { eq: "hiking" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    paddling: allSitesJson(
      filter: { the_type: { eq: "paddling" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    biking: allSitesJson(
      filter: { the_type: { eq: "biking" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    fishing: allSitesJson(
      filter: { the_type: { eq: "fishing" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    food: allSitesJson(
      filter: { the_type: { eq: "food" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    discover: allSitesJson(
      filter: { the_type: { eq: "discover" } }
      sort: { name: ASC }
      limit: 8
    ) {
      nodes {
        ...SitesCardFragment
      }
    }

    latestNews: allNewsJson(sort: { publish_at: DESC }, limit: 3) {
      nodes {
        ...NewsCardFragment
      }
    }

    latestEvents: allEventsJson(sort: { occurs_at: ASC }, limit: 6) {
      nodes {
        ...EventsCardFragment
      }
    }

    latestArticles: allArticlesJson(sort: { publish_at: DESC }, limit: 3) {
      nodes {
        ...ArticlesCardFragment
      }
    }

    latestTours: allToursJson(sort: { publish_at: DESC }, limit: 2) {
      nodes {
        ...ToursCardFragment
      }
    }

    imgHero: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "content/fishing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

const MapWrap = styled.section`
  height: 80vh;
  min-height: ${em(220)};
  max-height: ${em(1280)};

  > h2 {
    ${hideVisually()}
  }
`

const Highlights = styled.div`
  padding: ${em(40)} 0 ${em(100)};

  @media ${({ theme }) => theme.mq.smallDown} {
    padding: ${em(28)} 0 ${em(44)};
  }

  > * + * {
    margin-top: ${em(90)};

    @media ${({ theme }) => theme.mq.smallDown} {
      margin-top: ${em(46)};
    }
  }
`
