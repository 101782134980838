import React from "react"
import { Infobox as InfoboxComponent } from "@outdoormap/gatsby-plugin-brand-sites"

import Link from "../link"
import Styled from "../styled"
import { Anchor } from "../styled/anchor"
import { Heading7 } from "../styled/heading"
import { ReactComponent as SvgSheet } from "../../assets/images/icons/sheet.svg"

export default function Infobox({ data }) {
  return (
    <InfoboxComponent
      data={data}
      Link={Link}
      Anchor={Anchor}
      Title={Heading7}
      Styled={Styled}
      DocumentSvg={SvgSheet}
    />
  )
}
