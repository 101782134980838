import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"

import Link from "../link"
import { Heading4, Heading7 } from "../styled/heading"

const TAG_COLORS = {
  __default__: {
    text: `white`,
    background: `vattenbla900`,
  },
  // // pages:
  // map: {
  //   text: `white`,
  //   background: `red`,
  // },
  // articles: {
  //   text: `white`,
  //   background: `purple`,
  // },
  // events: {
  //   text: `white`,
  //   background: `aquaBlue`,
  // },
  // news: {
  //   text: `white`,
  //   background: `gold`,
  // },
  // // types:
  // hiking: {
  //   text: `white`,
  //   background: `blue`,
  // },
  // climbing: {
  //   text: `white`,
  //   background: `blue`,
  // },
  // canoeing: {
  //   text: `white`,
  //   background: `blue`,
  // },
  // biking: {
  //   text: `white`,
  //   background: `blue`,
  // },
  // diving: {
  //   text: `white`,
  //   background: `blue`,
  // },
  // wintersports: {
  //   text: `white`,
  //   background: `blue`,
  // },
}

const determineTag = (translations, result, defaultTitle) => {
  const tags = {}
  translations.types.forEach(
    (type) => (tags[type.slug] = { title: type.title, type: type.name })
  )
  Object.entries(translations.pages).forEach(
    ([type, page]) => (tags[page.slug] = { title: page.title, type })
  )

  const resultType = result.link.split(`/`)[3]

  return tags[resultType] || { title: defaultTitle, type: `__default__` }
}

export default function SearchResult({ result }) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          information
        }

        types {
          title
          slug
          name
        }

        pages {
          articles {
            title
            slug
          }

          events {
            title
            slug
          }

          news {
            title
            slug
          }

          map {
            slug
            title
          }
        }
      }
    }
  `)

  const title = result?.pagemap?.metatags?.[0]?.[`og:title`] || result.title
  const url = new URL(result.link).pathname
  const description = result.snippet
  const tag = determineTag(
    translations,
    result,
    translations.global.information
  )
  const img =
    result?.pagemap?.cse_thumbnail?.[0]?.src ||
    result?.pagemap?.cse_image?.[0]?.src

  return (
    <article>
      <ContainerLink to={url}>
        <Image>{img && <img src={img} alt={title} loading="lazy" />}</Image>

        <Title as="h2">
          <Heading4 as="span">{title}</Heading4>
        </Title>

        {description && <Description>{description}</Description>}

        {tag && (
          <Tag type={tag.type}>
            <Heading7 as="span">{tag.title}</Heading7>
          </Tag>
        )}
      </ContainerLink>
    </article>
  )
}

const ContainerLink = styled(Link)`
  padding: ${em(24)} ${em(20)};
  position: relative;
  display: grid;
  gap: ${em(16)};
  row-gap: ${em(10)};
  grid-auto-flow: dense;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "a a b b b b b"
    "a a c c c c c";
  border-radius: ${({ theme }) => theme.br.normal};
  background-color: ${({ theme }) => theme.colors.white};

  &:active {
    opacity: 0.8;
  }

  @media ${({ theme }) => theme.mq.xsmallDown} {
    padding: ${em(18)} ${em(14)};
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
`
const Title = styled.h2`
  grid-area: b;
  padding-top: ${em(10)};

  a:hover & {
    color: ${({ theme }) => theme.colors.abla};
  }
`

const Description = styled.p`
  max-width: 100%;
  grid-area: c;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const Image = styled.figure`
  grid-area: a;
  width: 100%;
  max-height: ${em(160)};
  aspect-ratio: 4 / 3;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.br.normal};
  background-color: ${({ theme }) => theme.colors.grasten};

  a:hover & {
    opacity: 0.8;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
`

const Tag = styled.p`
  padding: ${em(5)} ${em(18)};
  position: absolute;
  z-index: 10;
  top: 0;
  right: ${em(-20)};
  translate: 0 -50%;
  font-size: ${em(14)};
  border-radius: ${({ theme }) => theme.br.small};
  color: ${({ type, theme }) =>
    theme.colors[TAG_COLORS[type]?.text || TAG_COLORS.__default__.text]};
  background-color: ${({ type, theme }) =>
    theme.colors[
      TAG_COLORS[type]?.background || TAG_COLORS.__default__.background
    ]};

  @media ${({ theme }) => theme.mq.smallDown} {
    top: ${em(-5)};
    right: ${em(0)};
  }
`
