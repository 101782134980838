// TODO: rename `.do-unstyle` to `--unstyle`

import styled, { css } from "styled-components"
import { rem } from "polished"

import {
  heading1Styles,
  heading2Styles,
  heading3Styles,
  heading4Styles,
  // heading5Styles,
  // heading6Styles,
} from "./heading"
import { uListStyles, oListStyles, dListStyles } from "./list"
import { paragraphStyles, paragraphLargeStyles } from "./paragraph"
import { blockquoteStyles } from "./blockquote"
import { tableStyles } from "./table"
import { imageStyles } from "./image"
import { anchorStyles } from "./anchor"
import { codeStyles } from "./code"
import { figureStyles } from "./figure"
import { iframeStyles } from "./iframe"
import { markStyles } from "./mark"
import { hrStyles } from "./hr"

const elementsToStyles = Object.entries({
  h1: heading1Styles,
  h2: heading2Styles,
  h3: heading3Styles,
  h4: heading4Styles,
  // h5: heading5Styles,
  // h6: heading6Styles,
  ul: uListStyles,
  ol: oListStyles,
  dl: dListStyles,
  p: paragraphStyles,
  blockquote: blockquoteStyles,
  table: tableStyles,
  img: imageStyles,
  a: anchorStyles,
  code: codeStyles,
  figure: figureStyles,
  iframe: iframeStyles,
  mark: markStyles,
  hr: hrStyles,
})

const Styled = styled.div`
  ${elementsToStyles.map(
    ([element, styles]) => css`
      ${element}:not(.do-unstyle) {
        ${styles}
      }
    `
  )}

  /* default element spacing */

  > * + * {
    margin-top: ${rem(20)};
  }

  /* extra spacing for some elements that aren't the first element */

  ${[`h1`, `h2`, `h3`, `h4`, `h5`, `h6`].map(
    (sel) => css`
      > * + ${sel} {
        margin-top: ${rem(40)};
      }
    `
  )}

  /* leading paragraph */

  p:first-child {
    ${({ leadingParagraph }) => leadingParagraph && paragraphLargeStyles}

    &:not(:only-child) {
      ${({ theme }) => theme.fonts.set(`primary`, `medium`)}
    }
  }

  /* warning modifier */

  &.--warning {
    color: ${({ theme }) => theme.colors.alert};
  }

  /* table container */
  /* TODO: rename to '.-table-container' */

  .styled-table-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    table:not(.do-unstyle) {
      word-break: normal;
    }
  }
`

export default Styled
