import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"

import Link from "../link"
import { Heading4, Heading5 } from "../styled/heading"
import { ReactComponent as SvgComment } from "../../assets/images/icons/comment.svg"
import { ReactComponent as SvgGuide } from "../../assets/images/icons/guide.svg"
import { ReactComponent as SvgMapFlag } from "../../assets/images/icons/map-flag.svg"
import { ReactComponent as SvgMapLocation } from "../../assets/images/icons/map-location.svg"

const iconsMapping = {
  mapLocation: <SvgMapLocation />,
  comment: <SvgComment />,
  mapFlag: <SvgMapFlag />,
  mobileMessage: <SvgGuide />,
}

const SvgIcon = ({ name }) => iconsMapping[name]

export default function GoodToKnow() {
  const {
    translations: {
      footer: { goodToKnow },
    },
  } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        footer {
          goodToKnow: good_to_know {
            title
            items {
              url
              title
              icon
            }
          }
        }
      }
    }
  `)

  if (!goodToKnow.items.length) return null

  return (
    <Container>
      <div>
        <Heading5
          as="h2"
          dangerouslySetInnerHTML={{ __html: goodToKnow.title }}
        />

        <Links>
          {goodToKnow.items.map((item, i) => (
            <li key={i}>
              <Link to={item.url}>
                <Icon>
                  <SvgIcon name={item.icon} aria-hidden="true" />
                </Icon>

                <Heading4
                  as="span"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              </Link>
            </li>
          ))}
        </Links>
      </div>
    </Container>
  )
}

const Container = styled.section`
  padding: ${em(50)} 0 ${em(80)};
  background-color: ${({ theme }) => theme.colors.vattenbla400};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${em(30)} 0 ${em(50)};
  }

  > div {
    ${({ theme }) => theme.grid.container()}
  }
`

const Icon = styled.div`
  width: ${em(70)};
  height: ${em(70)};
  margin: 0 auto;
  margin-bottom: ${em(15)};
  display: block;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.foreground};

  @media ${({ theme }) => theme.mq.largeDown} {
    width: ${em(50)};
    height: ${em(50)};
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
    padding: 26% 30%;
  }
`

const Links = styled.ul`
  margin-top: ${em(20)};
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  border-radius: ${({ theme }) => theme.br.normal};
  border: 1px solid ${({ theme }) => theme.colors.foregoround};

  li {
    min-width: 25%;
    margin-top: -1px;
    margin-left: -1px;
    flex: 1;
    border: solid ${({ theme }) => theme.colors.foregoround};
    border-width: 1px 0 0 1px;

    @media ${({ theme }) => theme.mq.largeDown} {
      min-width: 50%;
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      min-width: 100%;
    }
  }

  a {
    height: 100%;
    padding: ${em(50)} ${em(20)};
    display: block;
    outline-offset: ${em(-10)};

    @media ${({ theme }) => theme.mq.largeDown} {
      padding: ${em(20)};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.vattenbla200};
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  ${Heading4} {
    ${({ theme }) => theme.fonts.set(`primary`, `medium`)};
  }
`
