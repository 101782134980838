import React from "react"
import { graphql } from "gatsby"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Post from "../components/post"

export default function NewsPostTemplate({
  data: { translations, news, latest },
}) {
  return (
    <Layout>
      <Post
        data={news}
        other={latest}
        otherHeading={translations.pages.news.title}
        otherCta={translations.global.seeAll}
        otherCtaUrl={`/${translations.pages.news.slug}`}
      />
    </Layout>
  )
}

export const Head = ({ data: { news } }) => {
  return (
    <Meta
      data={{
        title: news.title,
        socialImage: news.images[0]?.images.fallback.src,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    translations: translationsJson {
      global {
        seeAll: see_all
      }

      pages {
        news {
          title
          slug
        }
      }
    }

    news: newsJson(id: { eq: $id }) {
      title
      body
      date: publish_at(formatString: "YYYY–MM–DD")
      images {
        ...NKimageFragment
      }
      sites {
        ...SitesCardFragment
      }
      files {
        id
        url
        text
      }
      links {
        id
        url
        text
      }
    }

    latest: allNewsJson(
      filter: { id: { ne: $id } }
      sort: { publish_at: DESC }
      limit: 6
    ) {
      nodes {
        ...NewsCardFragment
      }
    }
  }
`
