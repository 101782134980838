import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { ImagesCarousel } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading2, Heading6, Heading7 } from "../styled/heading"
import { Paragraph } from "../styled/paragraph"
import { ReactComponent as SvgCalendar } from "../../assets/images/icons/calendar.svg"

export default function EventHero({ data: { title, openTimes, images } }) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          event
          openingHours: opening_hours
        }
      }
    }
  `)

  return (
    <Container>
      <Inner>
        <Info>
          <Caption>
            <SvgCalendar aria-hidden="true" />

            <Heading6
              as="span"
              dangerouslySetInnerHTML={{ __html: translations.global.event }}
            />
          </Caption>

          <Title>
            <Heading2 as="span" dangerouslySetInnerHTML={{ __html: title }} />
          </Title>

          {!!openTimes?.length && (
            <Date>
              <Heading7
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: translations.global.openingHours,
                }}
              />

              {openTimes.map((time, i) => (
                <Paragraph as="time" key={i} dateTime={time.opensIso}>
                  <span dangerouslySetInnerHTML={{ __html: time.opens }} />
                  –
                  <span dangerouslySetInnerHTML={{ __html: time.closes }} />
                </Paragraph>
              ))}
            </Date>
          )}
        </Info>

        <Images>
          <ImagesCarousel data={images} title={title} />
        </Images>
      </Inner>
    </Container>
  )
}

const Container = styled.header`
  ${({ theme }) => theme.grid.container()}
`

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: clamp(${em(30)}, 20%, ${em(80)});
  row-gap: ${em(30)};
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.br.normal};
  background-color: ${({ theme }) => theme.colors.vattenbla200};

  @media ${({ theme }) => theme.mq.mediumDown} {
    grid-template-columns: 1fr;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${em(20)};
  padding: ${em(40)} ${em(50)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${em(30)} ${em(20)};
  }
`

const Caption = styled.div`
  display: flex;
  gap: ${em(10)};
  align-items: center;
  color: ${({ theme }) => theme.colors.abla};

  svg {
    width: ${em(24)};
    height: ${em(24)};
    flex-shrink: 0;
    position: relative;
    top: ${em(-2)};
  }
`

const Date = styled.div`
  margin-top: auto;
`

const Title = styled.h1`
  max-width: 60ch;
`

const Images = styled.div`
  min-height: ${em(420)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    min-height: auto;
    aspect-ratio: 16 / 9;
  }
`
