import React from "react"
import { graphql } from "gatsby"
import { Meta } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import Post from "../components/post"

export default function ToursPostTemplate({
  data: { translations, tour, latest },
}) {
  return (
    <Layout>
      <Post
        data={tour}
        other={latest}
        otherHeading={translations.pages.tours.title}
        otherCta={translations.global.seeAll}
        otherCtaUrl={`/${translations.pages.tours.slug}`}
      />
    </Layout>
  )
}

export const Head = ({ data: { tour } }) => {
  return (
    <Meta
      data={{
        title: tour.title,
        socialImage: tour.images[0]?.images.fallback.src,
      }}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    translations: translationsJson {
      global {
        seeAll: see_all
      }

      pages {
        tours {
          title
          slug
        }
      }
    }

    tour: toursJson(id: { eq: $id }) {
      title
      body
      date: publish_at(formatString: "YYYY–MM–DD")
      images {
        ...NKimageFragment
      }
      sites {
        ...SitesCardFragment
      }
      files {
        id
        url
        text
      }
      links {
        id
        url
        text
      }
    }

    latest: allToursJson(
      filter: { id: { ne: $id } }
      sort: { publish_at: DESC }
      limit: 6
    ) {
      nodes {
        ...ToursCardFragment
      }
    }
  }
`
