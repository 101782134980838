import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"

import Link from "../link"
import { Heading4 } from "../styled/heading"

export default function Menus() {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        types {
          slug
          title
          name
        }

        footer {
          types
          explore {
            title
            items {
              url
              title
            }
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Group>
        <Heading4
          as="h2"
          dangerouslySetInnerHTML={{ __html: translations.footer.types }}
        />

        <ul>
          {translations.types.map((type, i) => (
            <li key={i}>
              <Link to={`/${type.slug}`}>{type.title}</Link>
            </li>
          ))}
        </ul>
      </Group>

      <Group secondary={true}>
        <Heading4
          as="h2"
          dangerouslySetInnerHTML={{
            __html: translations.footer.explore.title,
          }}
        />

        <ul>
          {translations.footer.explore.items.map((item, i) => (
            <li key={i}>
              <Link to={item.url}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </Group>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: ${em(20)};

  @media ${({ theme }) => theme.mq.xsmallDown} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`

const Group = styled.section`
  ${Heading4} {
    margin-bottom: ${em(15)};
    color: ${({ theme }) => theme.colors.laxrose};
  }

  ul {
    column-count: 2;
    column-gap: ${em(30)};

    @media ${({ theme }) => theme.mq.xlargeDown} {
      column-count: 1;
    }
  }

  li {
    width: 100%;
    margin-bottom: ${em(10)};
    clear: both;
    display: inline-block;
    font-size: ${({ secondary }) => em(secondary ? 16 : 18)};
    line-height: 1.2;

    @media ${({ theme }) => theme.mq.mediumDown} {
      font-size: ${({ secondary }) => em(secondary ? 14 : 16)};
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.5;
    }
  }
`
