import React, { useRef } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import styled, { css } from "styled-components"
import { em } from "polished"

import { ReactComponent as SvgMagnifyingGlass } from "../../assets/images/icons/magnifying-glass.svg"

export default function SearchForm({
  formProps = {},
  inputProps = {},
  buttonProps = {},
}) {
  const { site, translations } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          name
        }
      }

      translations: translationsJson {
        pages {
          search {
            title
            slug
          }
        }
      }
    }
  `)

  const inputRef = useRef()

  return (
    <Form
      action={`/${translations.pages.search.slug}`}
      method="get"
      {...formProps}
      onSubmit={(e) => {
        e.preventDefault()
        formProps?.onSubmit?.(e)
        navigate(
          `/${translations.pages.search.slug}/${inputRef.current.value}/`
        )
      }}
    >
      <Input
        type="search"
        name="query"
        placeholder={`${translations.pages.search.title} ${site.siteMetadata.name}`}
        {...inputProps}
        ref={(node) => {
          inputRef.current = node
          switch (typeof inputProps?.ref) {
            case `function`: {
              inputProps.ref(node)
              break
            }
            case `object`: {
              inputProps.ref.current = node
              break
            }
          }
        }}
      />

      <Button
        type="submit"
        title={translations.pages.search.title}
        {...buttonProps}
      >
        <SvgMagnifyingGlass aria-label={translations.pages.search.title} />
      </Button>
    </Form>
  )
}

const Form = styled.form`
  position: relative;
  display: flex;
`

const Input = styled.input`
  width: 100%;
  height: ${em(42)};
  padding: 0 ${em(50)} 0 ${em(20)};
  font-size: ${em(20)};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ theme, inModal }) =>
      inModal ? `transparent` : theme.colors.foreground};
  border-radius: ${({ theme }) => theme.br.normal};
  outline: none;

  ${({ inModal }) =>
    inModal &&
    css`
      box-shadow: 0 0 ${em(60)} rgba(0, 0, 0, 1);
    `}

  &::placeholder {
    color: ${({ theme }) => theme.colors.forgedsteal};
  }

  &:focus {
    border-color: ${({ theme, inModal }) =>
      inModal ? `transparent` : theme.colors.active};
  }
`

const Button = styled.button`
  ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

  width: ${em(50)};
  height: 100%;
  margin-left: ${em(-55)};
  color: ${({ theme }) => theme.colors.forgedsteal};
  outline-offset: ${em(-8)};

  &:hover {
    color: ${({ theme }) => theme.colors.active};
  }

  svg {
    width: 100%;
    height: 100%;
    padding: ${em(12)};
    display: block;
  }
`
