import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { em } from "polished"

import Link from "../link"
import ImagePlaceholder from "../image-placeholder"
import { Heading4 } from "../styled/heading"

export default function EventCard({
  event: { title, image, date, path },
  headingNum,
}) {
  return (
    <Container>
      <Link to={path} aria-label={title}>
        <Image>
          {image && <GatsbyImage image={image} alt={title} />}

          {!image && <ImagePlaceholder alt={title} />}
        </Image>

        <Text>
          {title && (
            <Title as={`h${Math.min(headingNum, 6)}`}>
              <Heading4 as="span" dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
          )}

          {date && (
            <Date dateTime={date} dangerouslySetInnerHTML={{ __html: date }} />
          )}
        </Text>
      </Link>
    </Container>
  )
}

const Image = styled.figure`
  position: relative;
  overflow: hidden;
  border-top-left-radius: ${({ theme }) => theme.br.normal};
  border-bottom-left-radius: ${({ theme }) => theme.br.normal};
  background-color: ${({ theme }) => theme.colors.kullgront};

  a:hover & {
    opacity: 0.8;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const Title = styled.h2``

const Date = styled.time`
  margin-top: ${em(8)};
  display: block;
  font-size: ${em(14)};
`

const Text = styled.div`
  padding: ${em(22)};

  @media ${({ theme }) => theme.mq.smallDown} {
    padding: ${em(16)};
  }
`

const Container = styled.article`
  width: 100%;
  display: flex;

  a {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.br.normal};
    outline-offset: ${em(4)};

    &:hover {
      background-color: ${({ theme }) => theme.colors.vattenbla200};
    }
  }
`
