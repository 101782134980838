import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { ImagesCarousel, Map } from "@outdoormap/gatsby-plugin-brand-sites"

import CardsCarousel from "../cards-carousel"
import SitesCarousel from "../sites-carousel"
import PostCard from "../post-card"
import Infobox from "../infobox"
import Button from "../button"
import Styled from "../styled"
import { Heading1, Heading5, Heading7 } from "../styled/heading"
import { ParagraphLarge } from "../styled/paragraph"
import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

export default function Post({
  data,
  other,
  otherHeading,
  otherCta,
  otherCtaUrl,
}) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          download
          relatedSites: related_sites
        }
      }
    }
  `)

  if (!data) return null

  const anySites = !!data?.sites?.length
  const mapQuery = anySites
    ? data.sites.map((s) => `site=${s.id}`).join(`&`)
    : null

  return (
    <>
      <Article>
        {!!data?.images?.length && (
          <Images>
            <ImagesCarousel data={data.images} title={data.title} />
          </Images>
        )}

        <Inner>
          <Header>
            {data.date && (
              <Date
                dateTime={data.date}
                dangerouslySetInnerHTML={{ __html: data.date }}
              />
            )}

            {data.title && (
              <Title dangerouslySetInnerHTML={{ __html: data.title }} />
            )}

            {data.tagline && (
              <Tagline dangerouslySetInnerHTML={{ __html: data.tagline }} />
            )}
          </Header>

          {data.body && (
            <Content dangerouslySetInnerHTML={{ __html: data.body }} />
          )}

          <InfoboxContainer>
            <Infobox data={data} />
          </InfoboxContainer>

          {anySites && (
            <Sites>
              <Heading7
                as="h2"
                dangerouslySetInnerHTML={{
                  __html: translations.global.relatedSites,
                }}
              />

              <SitesMap>
                <Map
                  query={mapQuery}
                  menu="fullscreen"
                  menuActive="fullscreen"
                  filters="fullscreen"
                  scrollZoom="false"
                  styleControl="fullscreen"
                  zoomControl="fullscreen"
                  measureControl="fullscreen"
                  locationControl="fullscreen"
                  printControl="fullscreen"
                />
              </SitesMap>

              <SitesCarousel
                sites={data.sites}
                siteHeadingNum={3}
                visibleSlides={2}
                contained={false}
              />
            </Sites>
          )}
        </Inner>
      </Article>

      {!!other?.nodes?.length && (
        <Other>
          <div>
            {otherHeading && (
              <OtherTitle>
                <Heading5
                  as="h2"
                  dangerouslySetInnerHTML={{ __html: otherHeading }}
                />
              </OtherTitle>
            )}

            <CardsCarousel visibleSlides={3}>
              {other.nodes.map((o) => (
                <PostCard
                  key={o.id}
                  post={o}
                  headingNum={3}
                  hideOutline={true}
                />
              ))}
            </CardsCarousel>

            {otherCta && otherCtaUrl && (
              <OtherCta>
                <Button
                  to={otherCtaUrl}
                  icon={<SvgArrowRight />}
                  iconpos="right"
                  display="anchor"
                  foreground="white"
                >
                  {otherCta}
                </Button>
              </OtherCta>
            )}
          </div>
        </Other>
      )}
    </>
  )
}

const Article = styled.article``

const Images = styled.div`
  width: 100%;
  height: 60vh;
  min-height: ${em(320)};
  max-height: ${em(480)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    height: auto;
    min-height: auto;
    max-height: auto;
    aspect-ratio: 16 / 9;
  }
`

const Inner = styled.div`
  padding: ${em(50)} 0 ${em(80)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding: ${em(30)} 0 ${em(40)};
  }
`

const Header = styled.header`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-bottom: ${em(30)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${em(20)};
  }
`

const Date = styled.time`
  margin-bottom: ${em(18)};
  display: block;
  color: ${({ theme }) => theme.colors.forgedsteal};
`

const Title = styled(Heading1)``

const Tagline = styled(ParagraphLarge)`
  margin-top: ${em(12)};
`

const Content = styled(Styled)`
  ${({ theme }) => theme.grid.container(`xnarrow`)}
`

const InfoboxContainer = styled.div`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-top: ${em(30)};

  &:empty {
    display: none;
  }

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-top: ${em(20)};
  }
`

const Sites = styled.footer`
  ${({ theme }) => theme.grid.container(`xnarrow`)}

  margin-top: ${em(50)};

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-top: ${em(30)};
  }
`

const SitesMap = styled.section`
  margin-top: ${em(20)};
  margin-bottom: ${em(20)};
  height: clamp(${em(180)}, 33vh, ${em(320)});

  > * {
    border-radius: ${({ theme }) => theme.br.normal};
    overflow: hidden;
  }
`

const Other = styled.section`
  background-color: ${({ theme }) => theme.colors.kullgront};

  > div {
    ${({ theme }) => theme.grid.container()}

    padding-top: ${em(50)};
    padding-bottom: ${em(50)};

    @media ${({ theme }) => theme.mq.mediumDown} {
      margin-top: ${em(20)};
      padding-top: ${em(20)};
      padding-bottom: ${em(20)};
    }
  }
`

const OtherTitle = styled.div`
  margin-bottom: ${em(20)};
  color: ${({ theme }) => theme.colors.white};
`

const OtherCta = styled.footer`
  margin-top: ${em(30)};
  text-transform: uppercase;

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-top: ${em(18)};
  }
`
