import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"
import { nanoid } from "nanoid"

import Link from "../link"
import SearchModal from "../search-modal"
import TypeIconSvg from "../type-icon-svg"
// import languages from "../../config/languages"
import { ReactComponent as ChevronDownSvg } from "../../assets/images/icons/chevron-down.svg"
import { ReactComponent as SvgMagnifyingGlass } from "../../assets/images/icons/magnifying-glass.svg"
import useRefState from "../../utils/use-ref-state"

const ENABLE_SEARCH = !!(
  process.env.GATSBY_SEARCH_API_KEY && process.env.GATSBY_SEARCH_ENGINE_ID
)

const ButtonLink = ({ to, ...props }) =>
  to ? <Link to={to} {...props} /> : <button type="button" {...props} />

export default function Nav({ onSearchOpen, onSearchClose }) {
  const {
    translations: {
      types,
      pages: { search },
      header: { nav },
    },
  } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        types {
          name
          slug
          title
        }

        pages {
          search {
            title
            slug
          }
        }

        header {
          nav {
            types
            items {
              title
              # url
              items {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const [activeBase, activeBaseRef, setActiveBase] = useRefState(-1)
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const containerRef = useRef()
  const baseClassName = useRef(`a${nanoid()}`)

  // const langCurrent = languages.find((i) => i.code === process.env.GATSBY_LANG)
  // const langSub = []
  // languages.forEach(
  //   (lang) => lang.code !== langCurrent.code && langSub.push(lang)
  // )

  const items = [
    {
      isType: true,
      title: nav.types,
      items: types.map((t) => ({ url: `/${t.slug}`, ...t })),
    },
    ...nav.items,
  ]

  const baseClick = (e) => {
    const index = parseInt(e.currentTarget.dataset.index)
    setActiveBase(activeBaseRef.current === index ? -1 : index)
  }

  const searchModalRequestClose = () => {
    setSearchModalOpen(false)
  }

  const searchButtonClick = (e) => {
    e.preventDefault()
    setSearchModalOpen(true)
  }

  const docClick = (e) => {
    if (activeBaseRef.current > -1 && !containerRef.current?.contains(e.target))
      setActiveBase(-1)
  }

  const winKeyup = (e) => {
    if (
      e.key === `Escape` &&
      activeBaseRef.current > -1 &&
      document.activeElement &&
      containerRef.current?.contains(document.activeElement)
    ) {
      document
        .querySelector(
          `.${baseClassName.current}[data-index="${activeBaseRef.current}"]`
        )
        ?.focus()

      setActiveBase(-1)
    }
  }

  useEffect(() => {
    if (searchModalOpen && onSearchOpen) onSearchOpen()
    if (!searchModalOpen && onSearchClose) onSearchClose()
  }, [searchModalOpen])

  useEffect(() => {
    if (typeof document !== `undefined`)
      document.addEventListener(`click`, docClick)

    if (typeof window !== `undefined`)
      window.addEventListener(`keyup`, winKeyup)

    return () => {
      if (typeof document !== `undefined`)
        document.addEventListener(`click`, docClick)

      if (typeof window !== `undefined`)
        window.removeEventListener(`keyup`, winKeyup)
    }
  }, [])

  return (
    <>
      <Container ref={containerRef}>
        {items.map((base, i) => (
          <li key={i} className={i === activeBase ? `--active` : undefined}>
            <ButtonLink
              to={base.url}
              onClick={baseClick}
              className={baseClassName.current}
              data-index={i}
              aria-haspopup={!!base.items}
              aria-expanded={i === activeBase}
            >
              <span dangerouslySetInnerHTML={{ __html: base.title }} />

              {base.items && <ChevronDownSvg />}
            </ButtonLink>

            {base.items && (
              <Sub isType={base.isType}>
                {base.items.map((sub, j) => (
                  <li key={j}>
                    <Link to={sub.url}>
                      {base.isType && (
                        <TypeIconSvg
                          name={sub.name}
                          className={`--${sub.name}`}
                        />
                      )}

                      <span dangerouslySetInnerHTML={{ __html: sub.title }} />
                    </Link>
                  </li>
                ))}
              </Sub>
            )}
          </li>
        ))}

        {ENABLE_SEARCH && (
          <li>
            <ButtonLink to={`/${search.slug}`} onClick={searchButtonClick}>
              <span dangerouslySetInnerHTML={{ __html: search.title }} />

              <SvgMagnifyingGlass aria-hidden="true" className="--search" />
            </ButtonLink>
          </li>
        )}
      </Container>

      {ENABLE_SEARCH && (
        <SearchModal
          isOpen={searchModalOpen}
          onRequestClose={searchModalRequestClose}
        />
      )}
    </>
  )
}

const Sub = styled.ul`
  width: ${em(240)};
  margin-top: ${em(8)};
  padding: ${em(24)} ${em(14)};
  display: none;
  position: absolute;
  z-index: 1;
  top: 100%;
  right: 0;
  text-align: right;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 ${em(4)} ${em(10)} rgba(0, 0, 0, 0.2);
  border-radius: ${em(10)};
  animation: 0.2s ${({ theme }) => theme.easings.default};
  animation-name: ${({ theme }) => theme.animations.slideInY(-10)},
    ${({ theme }) => theme.animations.fadeIn};

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }

  @media ${({ theme }) => theme.mq.mediumDown} {
    width: 100%;
    position: static;
    margin-bottom: ${em(20)};
    padding: ${em(16)};
    text-align: left;
    box-shadow: 0 ${em(2)} ${em(6)} rgba(0, 0, 0, 0.2);
  }

  li {
    &:not(:last-child) {
      margin-bottom: ${em(6)};
    }
  }

  a {
    ${({ theme, isType }) =>
      theme.fonts.set(`primary`, isType ? `bold` : `medium`)};

    padding: ${em(4)} ${em(10)};
    display: flex;
    align-items: center;
    gap: ${em(16)};
    text-align: left;
    overflow: hidden;
  }

  span {
    font-size: ${em(18)};
  }

  svg {
    width: ${em(30)};
    height: ${em(30)};
    flex-shrink: 0;

    &.--food,
    &.--discover {
      padding: ${em(2)} 0;
    }
  }
`

const Container = styled.ul`
  display: flex;

  @media ${({ theme }) => theme.mq.mediumDown} {
    display: block;
    font-size: ${em(14)};
  }

  a,
  button {
    &:hover {
      color: ${({ theme }) => theme.colors.active};
    }

    &:active {
      opacity: 0.6;
    }
  }

  > li {
    position: relative;

    &.--active {
      > a,
      > button {
        color: ${({ theme }) => theme.colors.active};

        svg {
          transform: rotate(180deg);
        }
      }

      ${Sub} {
        display: block;
      }
    }

    > a,
    > button {
      ${({ theme }) => theme.fonts.set(`primary`, `bold`)};

      padding: ${em(10)} ${em(16)};
      display: flex;
      align-items: baseline;
      gap: ${em(10)};
      text-transform: uppercase;

      @media ${({ theme }) => theme.mq.mediumDown} {
        padding: ${em(6)} ${em(10)};
        margin: 0 ${em(-10)};
      }

      span {
        /* font-size: ${em(18)}; */
      }

      svg {
        width: ${em(14)};
        height: ${em(14)};
        flex-shrink: 0;
      }
    }
  }
`
