import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { em } from "polished"

import Header from "../header"
import Footer from "../footer"
import CookiesConsent from "../cookies-consent"

export default function Layout({ children }) {
  const { translations } = useStaticQuery(graphql`
    query {
      translations: translationsJson {
        global {
          skipToContent: skip_to_content
        }
      }
    }
  `)

  return (
    <>
      <SkipToContent href="#content">
        {translations.global.skipToContent}
      </SkipToContent>

      <Header />

      <main id="content">{children}</main>

      <Footer />

      <CookiesConsent />
    </>
  )
}

const SkipToContent = styled.a`
  ${({ theme }) => theme.fonts.set(`primary`, `semibold`)};

  padding: ${em(14)} ${em(22)};
  position: fixed;
  z-index: ${({ theme }) => theme.zindex.skipToMain};
  top: ${em(10)};
  left: ${em(10)};
  color: ${({ theme }) => theme.colors.sandbeige};
  background-color: ${({ theme }) => theme.colors.abla};
  outline-offset: ${em(4)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    font-size: ${em(13)};
  }

  &:not(:focus) {
    pointer-events: none;
    opacity: 0;
  }

  html.--mobile-header & {
    display: none;
  }
`
