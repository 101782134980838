const typesCategories = {
  hiking: [`33`, `109`, `116`, `7`], // hiking, circular-trail, pilgrim-trail, walking
  paddling: [`37`], // canoeing
  biking: [`31`, `16`, `119`], // biking, mountainbiking, bikeservice
  fishing: [`26`, `118`, `105`, `115`], // fishing, boat-launch, fishing-permit, jetty
  food: [`54`, `106`], // cafe, ice-cream
  discover: [
    `6`,
    `14`,
    `69`,
    `84`,
    `55`,
    `115`,
    `73`,
    `54`,
    `56`,
    `18`,
    `103`,
    `64`,
    `26`,
    `22`,
    `62`,
    `76`,
    `75`,
    `17`,
    `19`,
    `78`,
    `35`,
    `25`,
    `179`,
    `29`,
    `77`,
    `15`,
    `60`,
    `87`,
    `12`,
    `81`,
    `113`,
    `89`,
    `27`,
    `74`,
    `52`,
    `61`,
  ],
}

const typesCategoriesIds = []
Object.values(typesCategories).forEach((v) => typesCategoriesIds.push(...v))

const typesCategoriesPreselected = {
  ...typesCategories,
  // discover: []
}

module.exports = {
  typesCategories,
  typesCategoriesPreselected,
  typesCategoriesIds,
}
