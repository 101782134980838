import React, { useState, useEffect, useMemo } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { em, hideVisually } from "polished"
import { intersection } from "lodash"
import { Meta, Map, markdown } from "@outdoormap/gatsby-plugin-brand-sites"

import Layout from "../components/layout"
import PostsHighlights from "../components/posts-highlights"
import TypeHero from "../components/type-hero"
import SitesCarousel from "../components/sites-carousel"
import CardsGrid from "../components/cards-grid"
import SitesFilter from "../components/sites-filter"
import PostCard from "../components/post-card"
import EventCard from "../components/event-card"
import Styled from "../components/styled"
import { typesCategoriesPreselected } from "../config/type"

const collectCategories = (data) => {
  const categories = {}
  data.nodes.forEach(
    (n) => n.mainCategory && (categories[n.mainCategory.id] = n.mainCategory)
  )
  return Object.values(categories)
}

const collectTags = (data) => {
  const tags = {}
  data.nodes.forEach((n) => n.tags.forEach((t) => (tags[t.id] = t)))
  return Object.values(tags)
}

const filterSites = (sites, selectedCategories, selectedTags) => {
  if (!selectedCategories.length && !selectedTags.length) return sites
  return sites.filter((site) => {
    let include = false
    if (selectedCategories.length && site.mainCategory) {
      include = selectedCategories.includes(site.mainCategory.id)
      if (!include) {
        include = !!site.categories.find((c) =>
          selectedCategories.includes(c.id)
        )
      }
    }
    if (selectedTags.length && !include) {
      include = !!selectedTags.find((id) => site.tags.find((t) => t.id == id))
    }
    return include
  })
}

export default function TypeTemplate({
  data: {
    translations,
    heroImg,
    sites,
    latestArticles,
    latestEvents,
    latestNews,
  },
}) {
  const type = translations.types[0]
  const configCategoriesPreselected = typesCategoriesPreselected[type.name]
  const categories = useMemo(() => collectCategories(sites), [sites])
  const tags = useMemo(() => collectTags(sites), [sites])
  const getCategoriesIds = () => categories.map((c) => c.id)
  const getTagsIds = () => tags.map((t) => t.id)
  const preselectedCategories = configCategoriesPreselected.length
    ? intersection(typesCategoriesPreselected[type.name], getCategoriesIds())
    : getCategoriesIds()
  const preselectedTags = configCategoriesPreselected.length ? [] : getTagsIds()
  const [selectedCategories, setSelectedCategories] = useState(preselectedCategories) // eslint-disable-line prettier/prettier
  const [selectedTags, setSelectedTags] = useState(preselectedTags)

  const sitesFiltered = useMemo(
    () => filterSites(sites.nodes, selectedCategories, selectedTags),
    [sites.nodes, selectedCategories, selectedTags]
  )

  const mapQuery = [`guide=${process.env.GATSBY_NATURKARTAN_GUIDE_ID}`]
  selectedCategories.forEach((c) => mapQuery.push(`category=${c}`))

  const mapQueryChange = (query) => {
    const newCategories = query
      .map((q) =>
        q.type == `category` && getCategoriesIds().includes(q.value)
          ? q.value
          : null
      )
      .filter(Boolean)

    setSelectedCategories(newCategories)
  }

  useEffect(() => {
    if (
      sites.nodes.length &&
      !selectedCategories.length &&
      !selectedTags.length
    ) {
      setSelectedCategories(getCategoriesIds())
      setSelectedTags(getTagsIds())
    }
  }, [selectedCategories, selectedTags])

  return (
    <Layout>
      <Container>
        <TypeHero type={type} img={heroImg} />

        <MapWrap>
          <h2 dangerouslySetInnerHTML={{ __html: translations.global.map }} />

          <Map
            query={mapQuery.join(`&`)}
            onQueryChange={mapQueryChange}
            menu="fullscreen"
            scrollZoom="false"
            styleControl="fullscreen"
            zoomControl="true"
            measureControl="fullscreen"
            locationControl="fullscreen"
            printControl="fullscreen"
            legendControl="true"
            legend="true"
            zoom={8}
          />
        </MapWrap>

        <SitesFilterWrap>
          <SitesFilter
            categories={categories}
            tags={tags}
            selectedCategories={selectedCategories}
            selectedTags={selectedTags}
            onSelectCategories={setSelectedCategories}
            onSelectTags={setSelectedTags}
          />
        </SitesFilterWrap>

        <SitesCarouselWrap>
          <SitesCarousel
            sites={sitesFiltered}
            siteHeadingNum={2}
            visibleSlides={3}
            contained={false}
          />
        </SitesCarouselWrap>

        <Highlights>
          <CardsGrid
            heading={translations.pages.news.title}
            headingNum={2}
            moreText={translations.global.seeAll}
            moreLink={`/${translations.pages.news.slug}`}
          >
            {latestNews.nodes.map((news) => (
              <PostCard key={news.id} post={news} headingNum={3} />
            ))}
          </CardsGrid>

          <CardsGrid
            heading={translations.pages.events.title}
            headingNum={2}
            moreText={translations.global.seeAll}
            moreLink={`/${translations.pages.events.slug}`}
          >
            {latestEvents.nodes.map((event) => (
              <EventCard key={event.id} event={event} headingNum={3} />
            ))}
          </CardsGrid>

          <PostsHighlights
            posts={latestArticles.nodes}
            heading={translations.pages.articles.title}
            headingNum={2}
            moreText={translations.global.seeAll}
            moreLink={`/${translations.pages.articles.slug}`}
          />
        </Highlights>

        {type.description && (
          <Description>
            <div>
              <Styled
                dangerouslySetInnerHTML={{
                  __html: markdown(type.description),
                }}
              />
            </div>
          </Description>
        )}
      </Container>
    </Layout>
  )
}

export const Head = ({ data: { translations } }) => {
  const type = translations.types[0]

  return <Meta data={{ title: type.title, description: type.tagline }} />
}

export const pageQuery = graphql`
  query ($type: String!, $categories: [ID]!) {
    translations: translationsJson {
      global {
        map
      }

      types(name: $type) {
        name
        slug
        title
        tagline
        description
      }

      pages {
        articles {
          title
          slug
        }

        events {
          title
          slug
        }

        news {
          title
          slug
        }
      }
    }

    sites: allSitesJson(
      filter: { categories: { elemMatch: { id: { in: $categories } } } }
      sort: { name: ASC }
    ) {
      nodes {
        ...SitesCardFragment

        categories {
          id
        }

        tags(type: "locality") {
          id
          name
        }
      }
    }

    heroImg: file(
      dir: { regex: "/src/assets/images/content$/i" }
      name: { eq: $type }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    latestArticles: allArticlesJson(
      filter: { categories: { elemMatch: { id: { in: $categories } } } }
      sort: { publish_at: DESC }
      limit: 3
    ) {
      nodes {
        ...ArticlesCardFragment
      }
    }

    latestEvents: allEventsJson(
      filter: { categories: { elemMatch: { id: { in: $categories } } } }
      sort: { occurs_at: ASC }
      limit: 3
    ) {
      nodes {
        ...EventsCardFragment
      }
    }

    latestNews: allNewsJson(
      filter: { categories: { elemMatch: { id: { in: $categories } } } }
      sort: { publish_at: DESC }
      limit: 3
    ) {
      nodes {
        ...NewsCardFragment
      }
    }
  }
`

const Container = styled.div`
  padding-bottom: ${em(140)};

  @media ${({ theme }) => theme.mq.largeDown} {
    padding-bottom: ${em(40)};
  }
`

const SitesFilterWrap = styled.div`
  margin-top: ${em(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(30)};
  }
`

const SitesCarouselWrap = styled.div`
  ${({ theme }) => theme.grid.container()}

  margin-top: ${em(30)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-top: ${em(20)};
  }
`

const MapWrap = styled.div`
  height: 60vh;
  min-height: ${em(220)};
  max-height: ${em(640)};
  position: relative;

  > h2 {
    ${hideVisually()}
  }
`

const Highlights = styled.div`
  > * {
    margin-top: ${em(90)};

    @media ${({ theme }) => theme.mq.smallDown} {
      margin-top: ${em(46)};
    }
  }
`

const Description = styled.div`
  ${({ theme }) => theme.grid.container()}

  margin-top: ${em(90)};

  @media ${({ theme }) => theme.mq.smallDown} {
    margin-top: ${em(46)};
  }

  > div {
    max-width: 60ch;
  }
`
